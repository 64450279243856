import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [{
      path: '/',
      redirect: '/pc'
    },
    {
      path: '/pc',
      name: 'pc',
      component: () => import('@/views/pc/index.vue'),
      redirect: '/home',
      children: [{
          path: '/examine',
          name: 'examine',
          component: () => import('@/views/pc/examine.vue')
        },
        {
          path: '/examineMore',
          name: 'examineMore',
          component: () => import('@/views/pc/examineMore.vue')
        },
        {
          path: '/examineDetails',
          name: 'examineDetails',
          component: () => import('@/views/pc/examineDetails.vue')
        },
        {
          path: '/examineRelease',
          name: 'examineRelease',
          component: () => import('@/views/pc/examineRelease.vue')
        },
        {
          path: '/news',
          name: 'news',
          component: () => import('@/views/pc/news.vue')
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/pc/home.vue')
        },
        {
          path: '/gaokao',
          name: 'gaokao',
          component: () => import('@/views/pc/gaokao.vue')
        },
        {
          path: '/contact',
          name: 'contact',
          component: () => import('@/views/pc/contact.vue')
        }
      ]
    },
    {
      path: '/mobile',
      name: 'mobile',
      redirect: '/mobileHome',
      component: () => import('@/views/mobile/mobileIndex.vue'),
      children: [{
          path: '/mobileNews',
          name: 'mobileNews',
          component: () => import('@/views/mobile/mobileNews.vue')
        },
        {
          path: '/mobileHome',
          name: 'mobileHome',
          component: () => import('@/views/mobile/mobileHome.vue')
        },
        {
          path: '/mobileGaokao',
          name: 'mobileGaokao',
          component: () => import('@/views/mobile/mobileGaokao.vue')
        },
        {
          path: '/mobileContact',
          name: 'mobileContact',
          component: () => import('@/views/mobile/mobileContact.vue')
        }
      ]
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

let originPush = Router.prototype.push
let originReplace = Router.prototype.replace

Router.prototype.push = function push(location) {
  return originPush.call(this, location).catch(err => err)
}
Router.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch(err => err)
}