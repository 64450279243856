<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { navList } from './utils/navList.js'
export default {
  name: 'app',
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
      )
      return flag
    },
    toPath() {
      var pathName = window.location.href.split('#/')[1]
      if (pathName.includes('?')) {
        pathName = pathName.split('?')[0]
      }
      if (pathName.includes('mobile')) {
        pathName = pathName.split('mobile')[1]
      }
      if (this.isMobile()) {
        // alert("手机端");
        pathName =
          'mobile' + pathName.charAt(0).toUpperCase() + pathName.slice(1)
      } else {
        // alert("pc端");
        pathName = pathName.charAt(0).toLowerCase() + pathName.slice(1)
      }
      navList.forEach((item) => {
        if (pathName.toUpperCase().includes(item.name.toUpperCase())) {
          item.name = pathName
          sessionStorage.item = JSON.stringify(item)
        }
      })
      if (window.location.hash.includes('?')) {
        // 获取所有参数
        const params = new URLSearchParams(window.location.hash.split('?')[1])
        // 将所有参数转换为一个对象
        const paramsObj = Object.fromEntries(params)
        sessionStorage.paramsObj = JSON.stringify(paramsObj)
        this.$router.replace({
          path: '/' + pathName,
          query: paramsObj,
        })
      } else {
        this.$router.replace('/' + pathName)
        sessionStorage.removeItem('paramsObj')
      }
    },
  },
  watch: {
    $route: {
      handler(val, oldval) {
        this.toPath(val, oldval)
      },
      // 深度观察监听
      deep: true,
    },
  },
  mounted() {
    window.onload = function () {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
    }
  },
}
</script>

<style></style>
