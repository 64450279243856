export const navList = [{
    id: 1,
    title: '首页',
    name: 'home'
  },
  {
    id: 2,
    title: '公司动态',
    name: 'news'
  },
  // {
  //   id: 3,
  //   title: '小语种高考',
  //   name: 'gaokao'
  // },
  {
    id: 4,
    title: '聚趣网校',
    name: 'product'
  },
  {
    id: 5,
    title: '联系我们',
    name: 'contact'
  }
]